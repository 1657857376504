<template>
  <v-container fluid>
    <v-col
      v-if="loading"
      cols="12">
      <div class="text-center">
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </div>
    </v-col>
    <v-card
      v-else>
      <v-data-table
        :headers="headers"
        :items="orders"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': itemsPerPage,
        }"
        :server-items-length="orderCount"
        :options.sync="pagination"
        class="elevation-1"
      >
        <template
          slot="item"
          slot-scope="props">
          <tr
            class="-clickable"
            @click="openOrderDetails(props.item.id)">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.customerName }}</td>
            <td>{{ props.item.company }}</td>
            <td>
              <div
                class="mt-2 mb-2"
                v-for="(orderProduct, index) in props.item.orderProducts"
                :key="`orderproduct-${props.item.id}-${index}`">
                {{ orderProduct.name}}<br>
                ({{ orderProduct.start | date }} - {{ orderProduct.end | date }})
              </div>
            </td>
            <td>{{ props.item.status }}</td>
            <td>{{ props.item.created_at | datetime }}</td>
            <td>{{ props.item.total | euro }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import OrdersAPI from '@/api/orders'

export default {
  data() {
    return {
      itemsPerPage: [50, 100, 200],
      headers: [
        {
          text: '#',
          value: 'id',
        },
        {
          text: 'Kunde',
          value: 'customerName',
        },
        {
          text: 'Firma',
          value: 'company',
          sortable: false,
        },
        {
          text: 'Hilfsmittel',
          value: 'orderProducts',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Vom',
          value: 'created_at',
        },
        {
          text: 'Gesamtwert',
          value: 'total',
        },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['id'],
        sortDesc: [true],
      },
      loading: false,
      orders: [],
      orderCount: 0,
    }
  },
  created() {
    this.fetch()
  },
  watch: {
    'pagination.page': {
      handler() {
        this.fetch()
      },
    },
    'pagination.itemsPerPage': {
      handler() {
        this.fetch()
      },
    },
    'pagination.sortBy': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.fetch()
        }
      },
    },
    'pagination.sortDesc': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.fetch()
        }
      },
    },
  },
  methods: {
    fetch() {
      if (this.loading) {
        return
      }

      this.loading = true
      OrdersAPI.getOrders(this.pagination).then((response) => {
        this.orders = response.orders
        this.orderCount = response.count
        this.loading = false
      })
    },
    openOrderDetails(id) {
      this.$router.push(`/orders/${id}`)
    },
  },
}
</script>
